<template>
  <div>
    <div class="min-vh-100">
      <CRow class="no-gutters px-3 px-sm-0">
        <CCol cols="6">
          <h1 class="mr-sm-4 header">BANK</h1>
        </CCol>
        <CCol cols="6" class="text-right">
          <router-link to="/bank/details/0">
            <button type="button" class="btn btn-main-2 button">CREATE NEW</button>
          </router-link>
        </CCol>
      </CRow>
      <div class="bg-white-border px-4 px-sm-5 mt-3 py-4">
        <b-row>
          <b-col>
            <b-table
              responsive
              class="text-center"
              striped
              :fields="fields"
              :items="items"
              :busy="isBusy"
              show-empty
              empty-text="No matching records found"
            >
              <template v-slot:cell(imageUrl)="data">
                <div class="d-flex justify-content-center">
                  <div
                    class="image-bank-logo"
                    v-bind:style="{ 'background-image': 'url(' + data.item.imageUrl + ')' }"
                  ></div>
                  <div
                    class="image-bank-logo"
                    v-bind:style="{ 'background-image': 'url(' + data.item.mobileBankingImageUrl + ')' }"
                  ></div>
                </div>
              </template>
              <template v-slot:cell(id)="data">
                <b-button variant="link" class="px-1 py-0">
                  <router-link :to="'/bank/details/'+ data.item.id">
                    <font-awesome-icon icon="pencil-alt" class="text-warning" title="View" />
                  </router-link>
                </b-button>
                {{" "}} {{" "}}
                <b-button variant="link" class="px-1 py-0">
                  <font-awesome-icon
                    icon="trash-alt"
                    class="text-danger"
                    @click="openModalDelete(data.item)"
                  />
                </b-button>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </div>
    </div>
    <!-- Modal -->
    <ModalAlertConfirm
      msg="ยืนยันการลบ ?"
      :text="modalMessage"
      btnConfirm="Delete"
      colorBtnConfirm="danger"
      btnCancel="Close"
      ref="ModalAlertConfirm"
      @confirm="btnDelete"
    />
    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
  </div>
</template>

<script>
import InputSelect from "@/components/inputs/InputSelect";
import ModalAlertConfirm from "@/components/modal/alert/ModalAlertConfirm";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
export default {
  name: "user",
  components: {
    InputSelect,
    ModalAlertConfirm,
    ModalAlert,
    ModalAlertError
  },
  data() {
    return {
      modalMessage: "",
      requestDeleteUser: {
        userId: null
      },
      fields: [
        {
          key: "imageUrl",
          label: "Thumbnail",
          class: "w-100px text-nowrap"
        },
        {
          key: "name",
          label: "Name",
          class: "w-100px text-nowrap text-left"
        },
        {
          key: "id",
          label: "Action",
          class: "w-100px text-nowrap"
        }
      ],
      items: [],
      isBusy: false
    };
  },
  created: async function() {
    this.$isLoading = true;
    await this.getData();
    this.$isLoading = false;
  },
  methods: {
    getData: async function() {
      this.isBusy = true;
      let resData = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/bank/List`,
        null,
        this.$headers,
        null
      );
      if (resData.result == 1) {
        this.items = resData.detail.data;
        this.isBusy = false;
      }
    },
    openModalDelete(value) {
      this.requestDeleteUser.userId = value.id;
      this.modalMessage = "คุณต้องการลบ " + value.name + " ใช่หรือไม่?";
      this.$refs.ModalAlertConfirm.show();
    },
    btnDelete: async function() {
      this.$refs.ModalAlertConfirm.hide();
      let resData = await this.$callApi(
        "delete",
        `${this.$baseUrl}/api/bank/remove/${this.requestDeleteUser.userId}`,
        null,
        this.$headers,
        null
      );
      this.modalMessage = resData.message;
      if (resData.result == 1) {
        this.$refs.modalAlert.show();
        await this.getData();
      } else {
        this.$refs.modalAlertError.show();
      }
    }
  }
};
</script>       